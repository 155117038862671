@use '@angular/material' as mat;
.nav {
  .nav-subheader {
    display: flex;
    align-items: center;
    height: 48px;
    font-weight: 500;
    padding-left: 24px;
    margin-top: 8px;
    font-size: 12px;
    white-space: nowrap;
  }

  .nav-item {
    .nav-link {
      text-decoration: none !important;
      display: flex;
      align-items: center;
      height: 48px;
      padding: 0 10px;
      position: relative;
      overflow: hidden;
      cursor: pointer;
      user-select: none;
      color: currentColor;

      > .nav-link-title {
        flex: 1;
        white-space: nowrap;
        font-size: 0.7rem;
      }

      .nav-link-badge {
        min-width: 20px;
        height: 20px;
        line-height: 20px;
        padding: 0 7px;
        font-size: 11px;
        font-weight: 500;
        border-radius: 20px;
        transition: opacity 0.2s ease-in-out 0.1s;
      }

      &:hover {
        background-color: map-get($background, hover);
      }

      .mat-ripple-element {
        background-color: map-get($background, hover);
      }

      &.active {
        background-color: mat.get-color-from-palette(mat.$grey-palette, 200);

        .mat-ripple-element {
          background-color: mat.get-color-from-palette($accent, default-contrast, 0.1);
        }

        &,
        .nav-link-icon {
          color: mat.get-color-from-palette($accent, default-contrast);
        }

        .nav-link-badge {
          background: #ffffff !important;
          color: rgba(0, 0, 0, 0.87) !important;
        }
      }

      .nav-link-icon {
        margin-right: 5px;
      }

      .nav-link-icon,
      .collapse-arrow {
        font-size: 20px;
        width: 20px;
        height: 20px;
        min-width: 20px;
        min-height: 20px;
        line-height: 20px;
      }
    }

    &.nav-collapse {
      display: block;

      > .children {
        > .nav-item {
          > .nav-link {
            padding-left: 25px;
            &.active {
              background-color: black;
            }
          }

          > .children {
            > .nav-item {
              > .nav-link {
                padding-left: 27px;
              }
            }
          }
        }
      }
    }
  }

  > .nav-item {
    &.nav-collapse {
      // background: rgba(255, 255, 255, 0.82);
      transition: background 200ms ease-in-out;
      // border-radius: 15px 15px 0px 0px;
      margin: 0px 0px 0px 10px;

      &.open {
        background: rgba(0, 0, 0, 0.12);
      }
    }
  }

  &.horizontal {
    display: flex;
    flex-direction: row;

    .nav-item {
      &.nav-collapse {
        position: relative;

        .children {
          display: none;
          position: absolute;
          top: 0;
          left: 100%;
          z-index: 999;
          min-width: 330px;
          box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14),
            0 3px 14px 2px rgba(0, 0, 0, 0.12);

          &.open {
            display: block;
            background: white;
          }

          .nav-link {
            padding-left: 24px !important;
            height: 30px;
          }
        }
      }
    }

    > .nav-item {
      &.nav-collapse {
        position: relative;

        > .nav-link {
          height: 40px;

          .collapse-arrow {
            display: none;
          }
        }

        > .children {
          top: 100%;
          left: 0;
          font-size: 0.5rem;
        }
      }
    }
  }
}
