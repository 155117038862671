@use '@angular/material' as mat;
@import "@angular/material/theming";
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$primary: mat.define-palette(mat.$grey-palette, 900, 400, 800);
$accent: mat.define-palette(mat.$grey-palette, 300, 100, 700);
$warn: mat.define-palette(mat.$red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$theme: mat.define-light-theme($primary, $accent, $warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.

$custom-typography: mat.define-typography-config(
  $font-family: "Helvetica"
);
@include mat.core($custom-typography);
@include mat.all-component-themes($theme);

.mat-raised-button.mat-primary.mat-button-disabled {
  background: #e1e1e1 !important;
  color: rgba(0, 0, 0, 0.49) !important;
}

mat-card {
  button {
    border-radius: 100px !important;
  }
  .mat-raised-button.mat-accent {
    background: #fff !important;
    color: #000 !important;
    border-radius: 100px !important;
  }
  .mat-raised-button.mat-primary {
    border-radius: 8px !important;
  }
}
