/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;
@import "theme.scss";
$mat-white: (
  500: white,
  contrast: (
    500: $black-87-opacity,
  ),
);

$mat-black: (
  500: black,
  contrast: (
    500: white,
  ),
);

$mat-dark: (
  50: #ececee,
  100: #c5c6cb,
  200: #9ea1a9,
  300: #7d818c,
  400: #5c616f,
  500: #3c4252,
  600: #353a48,
  700: #2d323e,
  800: #262933,
  900: #1e2129,
  A100: #c5c6cb,
  A200: #9ea1a9,
  A400: #5c616f,
  A700: #2d323e,
  contrast: (
    50: $black-87-opacity,
    100: $black-87-opacity,
    200: $black-87-opacity,
    300: white,
    400: white,
    500: $white-87-opacity,
    600: $white-87-opacity,
    700: $white-87-opacity,
    800: $white-87-opacity,
    900: $white-87-opacity,
    A100: $black-87-opacity,
    A200: $white-87-opacity,
    A400: $white-87-opacity,
    A700: $white-87-opacity,
  ),
);

// Palettes

// Create the theme object (a Sass map containing all of the palettes).
$theme: mat.define-light-theme($primary, $accent, $warn);

$background: map-get($theme, background);
$foreground: map-get($theme, foreground);

@import "app/core/helpers/core.scss";
/* width */
::-webkit-scrollbar {
  width: 10px;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-ripple .mat-ripple-element {
  background-color: #a59a00!important;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background,.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background {
  background-color: #a59a00 !important;
}
/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #e8e8e8;
  border-radius: 10px;
}

div {
  padding-top: 0;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #d6d7d8;
  border-radius: 10px;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #9ea1a9;
}
html,
body {
  height: calc(100% - 20px);
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
.displayLibsCenter {
  margin: auto auto;
}
.full-page {
  height: 100% !important;
  min-height: 100% !important;
  max-height: 100% !important;
  position: relative;
}
.full-width {
  width: 100% !important;
}
.footer {
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
  width: 100%;
  height: 45px;
}
.message-box {
  max-width: 450px !important;
  .message-type-icon {
    font-size: 2rem;
  }
}
.custom-dialog-container .mat-dialog-container {
  margin: 0;
  padding: 0;
  max-width: 200vw !important;
  max-height: 100vh !important;
  border-radius: 30px !important;
}
.cdk-overlay-container{
  z-index:999; //lower then fixed header z-index so it goes behind it
}
@mixin dialogTitle {
  border-radius: 4px !important;
  color: inherit !important;
  margin: auto;
  mat-form-field {
    width: 100% !important;
  }
  
  /*  padding: 14px !important;
    padding-left: 24px !important;*/
}

mat-dialog-container {
  @include dialogTitle;
}

.logo-container {
  margin-top: 0;
  padding-top: 1em;
  height: auto;
  margin-bottom: 1%;
  flex-direction: row;
  justify-content: center;
  box-sizing: border-box;
  display: flex;
}

.custom-user-dialog-container .mat-dialog-container {
  margin: 0;
  padding: 0;
  border-radius: 30px !important;
  max-width: 200vw !important;
  max-height: 100vh !important;
  z-index: 400;
  .mat-dialog-content {
    margin: 0;
    padding: 0;
    max-height: 35vh;
  }
}

.custom-view-image-container .mat-dialog-container {
  margin: 0;
  padding: 0;
  border-radius: 30px !important;
  max-width: 200vw !important;
  max-height: 100vh !important;
  z-index: 400;
  .mat-dialog-content {
    margin: 0;
    padding: 0;
    max-height: 80vh !important;
  }
}

.custom-image-preview .mat-dialog-container {
  margin: 0px;
  padding: 0px;
  height: 100%;
  max-width: 85vw !important;
  max-height: 95vh !important;
}
.file-explorer-dialog-container .mat-dialog-container {
  margin: 0px;
  padding: 0px;
  width: 100vw !important;
  height: 100vh !important;
}
.blocking-loader .mat-dialog-container {
  background-color: white;
  border-radius: 10px !important;
  border: solid 3px #A59A00;
  padding: 12px;
}
.search-selection-items .mat-checkbox-inner-container {
  height: 30px !important;
  margin-right: 15px !important;
  width: 30px !important;
}
::ng-deep .mat-menu-panel {
  border-radius: 30px !important;
}
.full-page-width-headers {
  height: calc(100vh - 40px);
}
.image-selection .mat-checkbox-inner-container {
  background: rgba(255, 255, 255, 0.7);
  height: 20px !important;
  width: 20px !important;
}
.file-explorer .mat-tab-body-content {
  height: 70vh !important;
  padding: 24px;
}
.file-explorer .mat-tab-body-wrapper {
  flex-grow: 1;
  height: 100%;
}
.comment-search .mat-form-field-wrapper {
  padding: 0px !important;
}
.all-claim .mat-sort-header-arrow {
  color: white;
}
.attachments .mat-standard-chip {
  font-size: 0.8rem;
  min-height: 20px;
  padding: 4px;
}

.mat-flat-button.mat-primary,
.mat-raised-button.mat-primary,
.mat-fab.mat-primary,
.mat-mini-fab.mat-primary {
  border-radius: 8px !important;
  background: #41535D !important;
  color: #FFF !important;
  text-transform: capitalize;
}

html, body {
  min-height: 100vh;
  height: auto;
  margin: 0;
}
// body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
